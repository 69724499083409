import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vincent/Documents/Develop/Web/PersonalWebsite/website/src/components/layout-markdown.tsx";
import NewsletterLink from "../../components/newsletter-link";
import { ScenarioReplaysFollowUps } from "../../features/posts/components/scenario-replays";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`In `}<a parentName="p" {...{
        "href": "/blog/scenario-replays"
      }}>{`the previous article`}</a>{` in this series, I explained what scenario replays are and how they can increase development speed and improve collaboration and communication across teams. Now, it’s time to explain what’s needed to get them to work in a web application assuming a lot of the logic is client side. There’s many variations on this depending on what combination of technologies you’re using. So instead of trying to dive into technology-specific details, I’ll start in this article with a high-level overview.`}</p>
    <h2>{`What is a scenario replay, really?`}</h2>
    <p>{`The goal of a scenario replay is to be able to jump to any specific point in any of the workflows in your product. This means `}<em parentName="p">{`a scenario replay is a description of two parts`}</em>{`: 1) setting up the right pre-conditions and 2) being able to lay out a sequence of steps that should happen after that. Some of the pre-conditions you’ll want to be able to modify at the start of each scenario are:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Data sets: `}</strong>{`what data do I want to be loaded in the backend before loading the frontend? This also includes data like registered users and their profiles. It may live in one database, or multiple systems, but you’ll want to have a way of easily starting with an empty dataset and only inserting the data you’ll need for the scenario.`}</li>
      <li parentName="ul"><strong parentName="li">{`Authentication: `}</strong>{`who am I logged in as (if anyone) before starting the UI and replaying steps? It may also be that while you’re setting up the scenario, you’ll switch between multiple users. For example, user A subscribes to a feed, user B posts something to that feed, then the UI is shown as user A.`}</li>
      <li parentName="ul"><strong parentName="li">{`Starting point: `}</strong>{`which screen or URL is the first place the scenario navigates to?`}</li>
      <li parentName="ul"><strong parentName="li">{`Other edges of the system: `}</strong>{`what are the other things you want to modify for the scenario to reproduce exactly the result you want? Are there any cookies or local storage keys that need to be present? Do you want to fake a certain device type with a specific screen width? Is there some state in the application that remembers previous pages you navigated from in order to display breadcrumbs? This all depends on your exact business requirements.`}</li>
    </ul>
    <p>{`Then, we’ll need to be able to execute steps. Each step can do any of these things:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Set up per-step preconditions: `}</strong>{`most notably, if you want to be able to jump to specific loading or error states, this is the place where you can block and sabotage certain parts of your program, or reverse these things later. One step might show a loading screen, while a next step shows what happens when the loading is done.`}</li>
      <li parentName="ul"><strong parentName="li">{`Wait for some condition to be met: `}</strong>{`you might want to execute this step only once the page is fully loaded, or only some part of it. Or, you started a process in one step, which completes several steps later. An example is an unauthenticated user trying to post a reply, which initiates a login/registration flow, during which the user fills in different fields a clicks submit. The reply submission as a whole waits for this and afterwards you want to show what happens after the reply is successfully submitted.`}</li>
      <li parentName="ul"><strong parentName="li">{`Trigger a UI action: `}</strong>{`this is where you fill in fields, press buttons, move sliders, etc. automatically. Implicit in this may be that the step knows it’s finished once the UI action finishes, e.g. a button press triggering an async process returning a Javascript promise.`}</li>
    </ul>
    <h2>{`Describing a scenario`}</h2>
    <p>{`Now how does this translate to code? There’s a choice to make here. If you choose to write your scenario as code, you’ll have more flexibility in expressing how you want to set up your pre-conditions and what should happen during each step. However, if you want to be able to record scenarios from actual user input (so you can use them to generate links you can attach to bug reports), you’ll want to be able to easily serialize them. In this case, each scenario can be described as a data structure, like JSON. The downside of this is losing the flexibility of code. For every different category of actions you need to take, you now need to write some code that can interpret it. The options here are writing them in code, making them data-driven or a hybrid, each with their own complexity, effort and maintenance trade-offs.`}</p>
    <p>{`Writing them as code might look something like this (untyped Javascript for simplicity):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const addTodoItem = () => ({
  setup: async (app) => {
    await app.loadData({
      users: ["default-user"],
      todoLists: [
        {
          name: "house-chores",
          user: "default-user",
        },
      ],
    });
    await app.authentiateAs("default-user");
    await app.blockPromise(app.storage, "getTodoList");
    await app.navigateTo({
      route: "todo-list",
      listId: "house-chores",
    });
  },
  steps: {
    loading: async (app) => {
      await app.waitFor("todo-list", "startedLoading");
    },
    loaded: async (app) => {
      await app.unblockPromise(app.storage, "getTodoList");
      await app.waitFor("todo-list", "finishedLoading");
    },
    addedItem: async (app) => {
      await app.triggerAction("todo-list", "addItem", {
        label: "Wash dishes",
      });
      await app.waitFor("todo-list", "addedItem");
    },
  },
});
`}</code></pre>
    <p>{`Whereas the same as a data structure might look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "data": {
    "users": ["default"],
    "todoLists": [{ "name": "house-chores", "user": "default" }]
  },
  "user": "default",
  "start": { "route": "todo-list", "listId": "house-chores" },
  "steps": {
    "loading": [
      { "blockPromise": "app.storage.getTodoList" },
      { "waitFor": ["todo-list", "startedLoading"] }
    ],
    "loaded": [
      { "unblockPromise": "app.storage.getTodoList" },
      { "waitFor": ["todo-list", "finishedLoading"] }
    ],
    "addedItem": [
      {
        "triggerAction": {
          "target": "todo-list",
          "eventName": "addItem",
          "eventArgs": {
            "label": "Wash dishes"
          }
        }
      },
      { "waitFor": ["todo-list", "addedItem"] }
    ]
  }
}
`}</code></pre>
    <h2>{`Getting the scenario to run`}</h2>
    <p>{`Now that you know what to describe in each scenario, you’ll need some way of replaying (and ideally recording) them. The specifics of how to do everything to make that happen depend on your specific codebase and could be nice subjects for future articles. On a high-level you’ll need:`}</p>
    <ul>
      <li parentName="ul">{`Some way of `}<strong parentName="li">{`recognizing you’re running the application with a scenario`}</strong>{`. This may be with a special URL, or an action in a UI. Once you detect this, you can look up the right scenarios and set up the right pre-condition.`}</li>
      <li parentName="ul">{`Being able to `}<strong parentName="li">{`run your program with a clean slate`}</strong>{`. This means cleared sessions, an empty database and any other cleared side-effects your program generates while running.`}</li>
      <li parentName="ul"><strong parentName="li">{`Isolating the edges of your system`}</strong>{`. You’ll want to set up your program so you can control any systems your UI interacts with, such as the authentication mechanism so you can create and impersonate fake users, or external services such as Mailchip so you can test mailing list subscription.`}</li>
      <li parentName="ul"><strong parentName="li">{`Initialization of pre-conditions. `}</strong>{`Loading the right data into the database, local storage, and mocked out services like Mailchimp. Also, information gotten from browser APIs such as device width, etc. And, starting the UI at the right screen/URL.`}</li>
      <li parentName="ul">{`Being able to `}<strong parentName="li">{`trigger UI actions and wait for conditions to be completed`}</strong>{`. This needs a central point where you can dispatch UI actions, no matter the screen you’re on. Also, conditions to wait for could be dispatched through this central point.`}</li>
      <li parentName="ul">{`Bonus: being able to `}<strong parentName="li">{`block and sabotage different parts of your program`}</strong>{` to reproduce loading and error states. This might be done by having your entire program as one (nested) object of which you can monkey patch certain parts on the fly.`}</li>
      <li parentName="ul">{`Bonus: being able to `}<strong parentName="li">{`record and serialize UI actions:`}</strong>{` This requires another central point through which all UI actions flow, which receives `}<em parentName="li">{`serializable actions`}</em>{` to record and execute. This excludes opaque non-serializable structures, like Redux’ thunks, which are function calls that the program cannot inspect. Instead, you need data like `}<inlineCode parentName="li">{`{ target: ‘todo-list, eventType: ‘deleteItem’, eventArgs: { id: 123 } }`}</inlineCode></li>
    </ul>
    <h2>{`What’s next?`}</h2>
    <p>{`I’ll dive into the most interesting aspects of some of these complexities one by one as part of the `}<em parentName="p">{`scenario replays and cross-team collaboration`}</em>{` series. If you want to receive articles as they come out, you can `}<NewsletterLink mdxType="NewsletterLink">{`subscribe to the series by joining the mailing list`}</NewsletterLink>{`.`}</p>
    <ScenarioReplaysFollowUps current="/blog/scenario-replays-requirements" mdxType="ScenarioReplaysFollowUps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      